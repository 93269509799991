<template lang="pug">
  v-autocomplete(
    tabindex="0"
    prepend-inner-icon="mdi-magnify"
    no-resize
    filled
    label="Multi-Shop Organization"
    :loading="areMsosPending"
    :items="msos"
    @input="pickMsosTag"
    @blur="$emit('blur',$event)"
    :value="value"
    item-text="name"
    item-value="id"
    :rules="rules ? rules : null"
    :search-input.sync="search"
    :hint="hint"
    persistent-hint
  )
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { useFind } from 'feathers-vuex'

export default {
  name: 'MsosAutocomplete',
  props: {
    rules: {
      type: Array,
      required: false,
      default: () => []
    },
    value: {
      type: String
    },
    query: {
      type: Object,
      required: false,
      default: () => {}
    },
    hint: {
      type: String,
      required: false
    }
  },
  setup (props, context) {
    const search = ref('')

    const { Msos } = context.root.$FeathersVuex.api

    const query = {
      ...props.query,
      $limit: 5
    }
    const params = computed(() => {
      const paramsQuery = { ...query }

      if (search.value) {
        paramsQuery.name = {
          $regex: search.value,
          $options: 'gi'
        }
      }

      return { query: paramsQuery }
    })

    const fetchParams = computed(() => {
      const paramsQuery = { ...query }

      if (search.value) {
        paramsQuery.name = {
          $iRegexp: search.value
        }
      }

      return { query: paramsQuery }
    })

    const { items: msos, isPending: areMsosPending } = useFind({
      model: Msos,
      params,
      fetchParams
    })

    return {
      search,
      msos,
      areMsosPending
    }
  },
  methods: {
    pickMsosTag: function (msosTagId) {
      this.$emit('input', msosTagId)
    }
  }
}
</script>
